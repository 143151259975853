<template>
  <div>
    <div style="display: flex; padding: 10px; font-size: 14px">
      <div style="flex: 1; text-align: left; color: #aaa">
        <span v-if="title">{{ title }}</span>
        当前统计周期<span v-if="startDate && endDate">
          {{ startDate }} ~ {{ endDate }}
        </span>
        <span v-else> : {{ _startDate }} ~ {{ _endDate }} </span>
      </div>

      <div
        v-if="descData && descData.length > 0"
        style="
          text-align: right;
          display: flex;
          align-items: center;
          justify-content: center;
        "
        @click="descStatus = true"
      >
        <svg
          style="display: block"
          t="1647773694454"
          class="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          p-id="40717"
          width="16"
          height="13"
        >
          <path
            d="M512.439015 676.900636a75.978643 75.978643 0 0 0-52.235317 21.584842 74.251855 74.251855 0 0 0-21.584842 52.235317 73.820159 73.820159 0 0 0 147.20862 0 74.251855 74.251855 0 0 0-21.584841-52.235317 75.115249 75.115249 0 0 0-51.80362-21.584842z m0-463.6424A164.044797 164.044797 0 0 0 391.132204 259.0181 170.088553 170.088553 0 0 0 341.487068 369.964187c0 38.421018 80.727308 30.650475 83.749186 11.224118a139.438077 139.438077 0 0 1 31.945566-67.344707 80.295611 80.295611 0 0 1 58.279073-22.448235 77.70543 77.70543 0 0 1 57.415678 21.584842 69.071493 69.071493 0 0 1 21.153145 50.940226 66.913009 66.913009 0 0 1-12.087511 39.284412 366.078915 366.078915 0 0 1-47.486652 47.918349A224.050657 224.050657 0 0 0 481.356843 518.036201a177.859096 177.859096 0 0 0-13.382602 74.683552v24.175023h82.454095a160.591222 160.591222 0 0 1 6.475453-58.710769 155.41086 155.41086 0 0 1 35.830837-43.169684A410.543689 410.543689 0 0 0 665.691391 431.696834a129.50905 129.50905 0 0 0 17.267873-64.322828A146.345227 146.345227 0 0 0 636.336006 259.0181a169.225159 169.225159 0 0 0-124.760385-45.759864z m0-213.258236a511.992445 511.992445 0 1 0 511.560748 511.560748A512.424142 512.424142 0 0 0 512.007318 0z m0 958.366972a447.23792 447.23792 0 1 1 446.806223-447.23792A448.101314 448.101314 0 0 1 512.007318 958.366972z"
            fill="#0e932e"
            p-id="40718"
          ></path>
        </svg>

        <span style="font-weight: bold">数据说明</span>
      </div>

      <Modal class="statement" v-model="descStatus" fullscreen>
        <p slot="header" style="text-align: center">
          <span>{{ title }}数据说明</span>
        </p>
        <div class="statementContent">
          <p v-for="(item, key) in descData" :key="key">{{ item }}</p>
        </div>
        <div slot="footer">
          <Button size="large" long @click="descStatus = false">确定</Button>
        </div>
      </Modal>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from "moment";

export default {
  name: "descCom",
  components: {},
  props: {
    startDate: {
      type: String,
      default: "",
    },
    endDate: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    descData: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data () {
    return {
      // 选择日期
      _startDate: "",
      _endDate: "",
      // 是否显示提示框
      descStatus: false,
    };
  },
  methods: {},
  async created () {
    this._startDate =
      this.startDate || moment().subtract(7, "day").format("YYYY-MM-DD");

    this._endDate = this.endDate || moment().format("YYYY-MM-DD");
  },
};
</script>

<style scoped>
.statement .statementContent {
  background: white;
  color: #6a6f7d;
  font-weight: bold;
  border-radius: 10px;
  width: 96%;
  overflow: scroll;
  position: relative;
  z-index: 1009;
}

.statementContent p {
  margin-bottom: 10px;
}
</style>
